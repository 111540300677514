.ourSkillsDomain
{
    background: #F1F3F7;
    padding: 100px 0px 40px;
    margin-top: -10px;
    
    .skillWrap
    {
        margin-bottom: 60px;
        overflow: hidden;
        @media (max-width:375px) 
        {
            margin-bottom: 40px;
        }

        .skillIcon
        {
            float: left;
            // border: 1px solid rgba(192, 181, 150, .5);
            // border-radius: 50%;
            width: 120px;
            height: 120px;
            display: flex;
            align-items: center;
            justify-content: center;
            margin-right: 30px;
            @media (max-width:1200px) {
                margin-right: 10px;
            }
            i{
                color: #c0b596;
                transition: all .3s;
                &::before{
                    font-size: 45px;
                }
            }
        }
        // &:hover{
        //     .serviceIcon{
        //         i{
        //             color: #999;
        //         }
        //     }
        // }

        .skillContent
        {
            overflow: hidden;
            h3{
                color: #0F0E0E;
                font-size: 24px;
                padding-bottom: 10px;
                position: relative;
                margin-bottom: 10px;
                @media (max-width:375px) {
                    font-size: 20px;
                }
                &::before{
                    position: absolute;
                    content: "";
                    left: 0;
                    bottom: 0;
                    width: 30px;
                    height: 2px;
                    background: #CDBA6D;
                }
            }
            p{
                color: #555555;
                margin-bottom: 0;
            }
        }
    }
}