.sectionTitle
{
    text-align: center;
    margin-bottom: 60px;

    span
    {
        font-size: 16px;
        font-weight: 800;
        color: #CDBA6D;
        position: relative;
        text-transform: uppercase;
        display: block;
        line-height: 15px;
        margin-bottom: 5px;
    }

    h2
    {
        font-size: 36px;
        color: #0F0E0E;
        padding-bottom: 20px;
        position: relative;
        @media (max-width:375px) 
        {
            font-size: 25px;
        }
        
        &::before
        {
            content: "";
            position: absolute;
            left: 50%;
            transform: translateX(-50%);
            bottom: 0;
            width: 60px;
            height: 3px;
            background: #CDBA6D;
        }
    }
}