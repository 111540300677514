.contactArea
{
    position: relative;
    background-attachment: fixed;
    padding: 100px 0px;
    a {
        text-decoration: none;
    }
    
    a:link, a:visited {
        color: #CDBA6D;
    }
    
    a:hover {
        color: #3D3D3D
    }
    
    &::before
    {
        content: "";
        background-color: #F1F3F7;
        width: 100%;
        height: 100%;
        position: absolute;
        left: 0;
        top: 0;
    }

    .contactInfo
    {
        @media (max-width:768px) 
        {
            margin-bottom: 40px;
        }

        span
        {
            font-size: 16px;
            color: #CDBA6D;
        }

        h2
        {
            font-size: 36px;
            margin-bottom: 20px;
            margin-top: 10px;
            color: #0F0E0E;
            position: relative;
            display: inline-block;
            &::before{
                content: "";
                position: absolute;
                right: -68px;
                top: 25px;
                width: 60px;
                height: 1px;
                background: #c0b596;
                @media (max-width:1200px) {
                    width: 30px;
                    left: -35px;
                }
            }
        }

        h6
        {
            font-size: 18px;
            color: #CDBA6D;
            font-family: 'Open Sans', sans-serif;
            @media (max-width:1200px) {
                font-size: 16px;
            }
        }
        p{
            margin-top: 30px;
            color: #555555;
            line-height: 30px;
        }
    }
}