.pricingTableArea{
    padding: 100px 0px 70px;
    // background: #f5f5f5;
    
    .pricingWrapper
    {
        text-align: center;
        background: #fff;
        padding: 40px 50px 50px;
        margin-bottom: 30px;
        transition: all .3s;
        @media (max-width:1024px) {
            padding: 40px 40px 50px;
        }
        i{
            color: #CDBA6D;
            transition: all .3s;
        }
        h3{
            margin-bottom: 20px;
            font-size: 18px;
            transition: all .3s;
            font-weight: 600;
        }
        h2{
            font-size: 60px;
            font-family: 'Open Sans', sans-serif;
            font-weight: 600;
            margin-bottom: 10px;
            transition: all .3s;
            margin-right: 20px;
        }
        p{
            font-size: 16px;
            line-height: 30px;
            margin-bottom: 25px;
            transition: all .3s;
            @media (max-width:1024px) {
                font-size: 14px;
                line-height: 28px;
            }
        }
        .skillIcon
        {
            // float: left;
            // border: 1px solid rgba(192, 181, 150, .5);
            // border-radius: 50%;
            // text-align: center;
            display:block;
            margin:auto;
            width: 140px;
            height: 140px;

            display: flex;
            align-items: center;
            justify-content: center;

            margin-bottom: 20px;
            // margin-right: 30px;
            // @media (max-width:1200px) {
            //     margin-right: 10px;
            // }
            // i{
            //     color: #c0b596;
            //     transition: all .3s;
            //     &::before{
            //         font-size: 45px;
            //     }
            // }
        }
        &:hover{
            background: #CDBA6D;
            i,
            h3,
            h2,
            p{
                color: #fff;
            }
            .btnStyle{
                button{
                    background: #fff;
                    color: #CDBA6D;
                }
            }
        }
    }
}