.headerArea
{
    .headerTop 
    {
        .headerTopMainWrapper
        {
            border-bottom: 1px solid rgba(255,255,255,.07);
            padding: 10px 0;
        }
        background: #0F0E0E;

        ul
        {
            text-align: right;
            
            @media (max-width:767px) 
            {
                text-align: center;
            }

            li
            {
                .Icon
                {
                    margin-right: 5px;
                    margin-bottom: 1px;
                }
                display: inline-block;
                color: #fff;
                padding: 15px;
                font-size: 15px;

                @media (min-width:991px) and (max-width:1199px) 
                {
                    padding: 15px 6px 15px 6px;
                }​

                &:nth-child(2)
                {
                    position: relative;
                    padding-left: 0;
                    &::before
                    {
                        position: absolute;
                        content: "";
                        right: 0;
                        top: 14px;
                        width: 1px;
                        height: 25px;
                        background: rgba(255,255,255,.07);
                    }
                }
                &:last-child
                {
                    padding-right: 0;
                }
                i
                {
                    padding-right: 10px;
                    color: #CDBA6D;  
                }
            }
            &.Subtitle
            {
                font-weight: 800;

                li
                {
                    padding-left: 0px;
                    
                    @media (max-width:767px) 
                    {
                       padding: 0;
                    }
                    &::before
                    {
                        display: none;
                    }

                    @media (max-width:991px) 
                    {
                        padding-top: 15px;
                        padding-bottom: 8px;
                        font-size: 15px;
                    }

                    @media (max-width:1199px) 
                    {
                        font-size: 15px;
                    }
                }
                
                @media (max-width:991px) 
                {
                    justify-content: center!important;
                }
            }
        }

        .HeaderContact
        {
            @media (max-width:991px) 
            {
                text-align: center !important;
            }    
        }

        // Button style (Consultation gratuite)
        .btnStyle
        {
            a
            {
                background: transparent;
                font-size: 12px;
                font-weight: 800;
                color: #CDBA6D;
                padding: 12px 10px;
                border: 2px solid #CDBA6D;
                border-radius: 47px;
                text-transform: inherit;

                @media (max-width:991px) 
                {
                    font-size: 12px !important;
                    padding: 12px 10px;
                }
                @media (max-width:1199px) 
                {
                    font-size: 10px !important;
                    padding: 13px 6px;
                }
            }

            @media (max-width:991px) 
            {
                text-align: center !important;
                padding-bottom: 2px;
            }
        }
    }
}