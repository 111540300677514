.contactForm
{
    .formInput
    {
        margin-bottom: 30px;

        input,
        textarea,
        select
        {
            background: #f5f5f5;
            height: 50px;
            padding: 6px 15px;
            color:#555555;
            border-radius: 0;
            -webkit-box-shadow: none;
            box-shadow: none;
            border: 1px solid rgba(192, 181, 150, .5);
            &:focus
            {
                -webkit-box-shadow: none;
                box-shadow: none;
                border-color: #CDBA6D;
            }
            &::-webkit-input-placeholder
            {
                color: #bbb5a5;
                font-size: 14px;
            }
        }

        select
        {
            color: #555555;
            position: relative;
            -webkit-appearance: none;
            -ms-appearance: none;
            -o-appearance: none;
            appearance: none;
            -moz-appearance: none;
            background: #f5f5f5 url(../../../images/SelectIcon.png) no-repeat calc(100% - 15px) center;
        }
        textarea
        {
            height: 150px;
            padding: 15px;
        }

        p
        {
            text-transform: uppercase;
            font-size: 12px;
            color: #CDBA6D;
        }
    }
    
    button
    {
        font-size: 15px;
        font-weight: 700;
        color: #fff;
        padding: 9px 25px;
        border: 2px solid transparent;
        text-transform: capitalize;
        display: inline-block;
        transition: all .3s;
        background: #CDBA6D;
        &:hover
        {
            background:#0F0E0E;
        }
    }
}