.contactUsPageArea
{
    padding: 100px 0px;

    

    .contactUsInfo
    {
        a {
            text-decoration: none;
        }
        
        a:link, a:visited {
            color: #CDBA6D;
        }
        
        a:hover {
            color: #3D3D3D
        }
        
        h3
        {
            font-size: 30px;
            font-weight: 400;
            margin-bottom: 30px;
        }

        h4
        {
            font-size: 24px;
            color: #3D3D3D;
            margin-bottom: 10px;
            margin-top: 20px;
        }

        span
        {
            display: block;
            color:#555555;
        }
    }

    .contactUsForm
    {
        h3
        {
            margin-bottom: 40px;
            font-size: 30px;
        }
    }
}